<template>
  <el-dialog :title="title" :visible.sync="editVisable" width="700px" :close-on-click-modal="false" :before-close="closeEditDialog" v-ismove>
    <el-form :model="editForm" :rules="formRules" ref="editForm" label-width="120px" size="small">
      <el-form-item label="母公司：" v-if="editData.type === 'add'">
        <el-select v-model.number="editForm.ucParentId" placeholder="请选择" style="width:80%">
          <el-option v-for="parent in companyList" :label="parent.ucName" :value="parent.ucId" :key="parent.ucId"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="公司名称" prop="ucName">
        <el-input v-model="editForm.ucName" style="width:80%" clearable></el-input>
      </el-form-item>
      <el-form-item label="邮箱" prop="ucEmail">
        <el-input v-model="editForm.ucEmail" style="width:80%" clearable></el-input>
      </el-form-item>
      <el-form-item label="地址" prop="ucAddress">
        <el-input v-model="editForm.ucAddress" style="width:80%" clearable></el-input>
      </el-form-item>
      <el-form-item label="邮编" prop="ucPostCode">
        <el-input v-model="editForm.ucPostCode" style="width:80%" clearable></el-input>
      </el-form-item>
      <el-form-item label="公司座机" prop="ucPhone">
        <el-input v-model="editForm.ucPhone" style="width:80%" clearable></el-input>
      </el-form-item>
      <el-form-item label="负责人姓名">
        <el-input v-model="editForm.ucMasterName" style="width:80%" clearable></el-input>
      </el-form-item>
      <el-form-item label="负责人手机" prop="ucMasterMobile">
        <el-input v-model="editForm.ucMasterMobile" style="width:80%" clearable></el-input>
      </el-form-item>
      <el-form-item label="简介：" v-if="editData.type === 'add'">
        <el-input type="textarea" v-model="editForm.ucIntro" style="width:80%" clearable ></el-input>
      </el-form-item>
     </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="closeEditDialog">取消</el-button>
        <el-button type="primary" size="small" @click="commit()">确 定</el-button>
      </div>
    </el-dialog>
</template>

<script>
import { editCompany, addCompany, getIdCompany, getAllCompany } from '@/api/companies'
import * as validFn from '@/utils/validate'

export default {
  props: {
    editVisable: {
      type: Boolean,
      default() {
        return false
      }
    },
    editData: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      title: '添加公司',
      editForm: {
        ucName: '',
        ucLevel: '', // 层级
        ucEmail: '', //  邮箱
        ucMasterName: '', //  ucMasterName
        ucMasterMobile: '', //  负责人电话
        ucIntro: '', //  简介
        ucPath: '', // 层级路径
        ucPhone: '', // 公司电话
        ucLogoUrl: '', // Logo地址
        ucAddress: '', // 公司地址
        ucParentId: '',
        ucPostCode: ''
      },
      formRules: {
        ucName: [validFn.required()],
        ucLevel: [validFn.isIntegerNum()],
        ucEmail: [validFn.validateEmail()],
        ucMasterMobile: [validFn.checkMobilePhone()],
        ucPhone: [validFn.checkPhone()],
        // ucParentId: [{ required: true, message: '请输入母公司', trigger: 'blur' }],
        ucPostCode: [validFn.isPostCode()]
      },
      companyList: []
    }
  },
  watch: {
    editVisable: function() {
      if (this.editVisable && this.editData.type === 'edit') {
        this.title = '公司编辑'
        this.getCompanyInfo(this.editData.ucId)
      } else if (this.editVisable && this.editData.type === 'add') {
        this.getAllCompanyList()
      } else {
        this.$refs.editForm.resetFields()
      }
    }
  },
  methods: {
    // 获取该职位信息，拿到职位名和公司Id
    getCompanyInfo(ucId) {
      getIdCompany(ucId).then(response => {
        if (response.code === '000000' && response.data) {
          this.editForm = response.data
          console.log(this.editForm)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 获取所有公司 下拉列表
    getAllCompanyList() {
      getAllCompany().then(response => {
        if (response.code === '000000' && response.data) {
          this.companyList = response.data
          this.companyList.unshift({ ucName: '请选择', ucId: 0 })
        }
      }).catch(err => {
        console.log(err)
      })
    },
    commit() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          if (this.editData.type === 'add') {
            this.commitAdd()
          } else {
            this.commitEdit()
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    commitAdd() {
      addCompany(this.editForm).then(response => {
        if (response.code === '000000') {
          this.successTip('添加成功')
          this.success()
        } else {
          this.fail('添加失败')
        }
      }).catch(err => {
        console.log(err)
      })
    },
    commitEdit() {
      editCompany(this.editForm).then(response => {
        if (response.code === '000000') {
          this.successTip('修改成功')
          this.success()
        }
      }).catch(err => {
        console.log(err)
      })
    },
    closeEditDialog() {
      this.$emit('closeDialog')
    },
    success() {
      this.$emit('addSuccess')
    },
    // 成功提示
    successTip(msg) {
      this.$message({
        message: msg,
        type: 'success'
      })
    },
    // 错误提示
    fail(msg) {
      this.$message.error(msg)
    }
  }
}
</script>

<style>

</style>
