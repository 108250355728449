<template>
  <div class="app-container">
    <el-form :model="searchForm" size="small" ref="searchForm" inline>
      <el-form-item label="公司名称">
        <el-input v-model="searchForm.ucName" placeholder="公司名" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="getListCompany()">查询</el-button>
        <el-button type="success" size="small" v-if="roleId===1" @click="addClick()">添加公司</el-button>
      </el-form-item>
    </el-form>

    <el-table
      v-loading="isLoading"
      :data="dataList"
      border
      height="calc(100% - 120px)"
      style="width: 100%;"
      size="small"
      :header-cell-style="{textAlign:'center', background: '#41A1FF', color: '#ffffff'}">
      <el-table-column type="index" label="序号" width="50" align="center"></el-table-column>
      <el-table-column label="公司名称" prop="ucName" width="180" align="center"></el-table-column>
      <el-table-column label="邮编" prop="ucPostCode" width="120" align="center"></el-table-column>
      <el-table-column label="邮箱" prop="ucEmail" width="150" align="center"></el-table-column>
      <el-table-column label="座机" prop="ucPhone" width="120" align="center"></el-table-column>
      <el-table-column label="地址" prop="ucAddress" width="200" align="center"></el-table-column>
      <el-table-column label="负责人手机" prop="ucMasterMobile" width="120" align="center"></el-table-column>
      <el-table-column label="负责人姓名" prop="ucMasterName" width="120" align="center"></el-table-column>
      <el-table-column label="选中系统" align="center">
        <!-- show-overflow-tooltip -->
        <template slot-scope="scope">
          <el-tag v-for="(item, index) in scope.row.systemList" :key="index" size="mini" effect="plain">{{item.usName}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" width="150" label="操作">
        <template slot-scope="scope">
          <el-tooltip effect="dark" content="编辑" placement="top">
            <i class="table_icons el-icon-edit" @click="editClick(scope.row)"></i>
          </el-tooltip>
          <el-tooltip v-if="roleId===1" effect="dark" content="选系统" placement="top">
            <i class="table_icons el-icon-setting" @click="systemClick(scope.row.ucId)"></i>
          </el-tooltip>
          <el-tooltip effect="dark" content="删除" placement="top">
            <i class="table_icons el-icon-delete" @click="deleteCompany(scope.row.ucId)"></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <div class="block" style="text-align: center;margin-top:2em">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>

    <!-- 新增 编辑 -->
    <edit-dialog
      :editVisable="editVisable"
      :editData="editData"
      @closeDialog="closeDialog()"
      @addSuccess="addSuccess()"/>

    <!-- 编辑系统弹框 -->
    <el-dialog title="选择系统" :visible.sync="systemVisible" >
      <el-select v-model="editSystemData.usIds" multiple placeholder="可多选" style="width:80%;" filterable>
        <el-option
          v-for="item in useridAllsystem"
          :key="item.usId"
          :label="item.usName"
          :value="item.usId">
        </el-option>
      </el-select>
      <div slot="footer" class="dialog-footer">
        <el-button @click="systemVisible=false">取消</el-button>
        <el-button type="primary" @click="commitEditSystem()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { searchCompany, deleteCompany, getUcSystem, getUserSystem, editUcSystem } from '@/api/companies'
import editDialog from './components/CompanyEdit'

export default {
  components: {
    'edit-dialog': editDialog
  },
  data() {
    return {
      roleId: null,
      isLoading: false,
      searchForm: {
        ucName: ''
      },
      dataList: [],
      pageNum: 1,
      pageSize: 10,
      total: 0,
      editVisable: false,
      editData: {},
      systemVisible: false,
      useridAllsystem: [], // 根据用户id获取公司的所有系统
      editSystemData: {
        ucId: '',
        usIds: []
      }
    }
  },
  created() {
    this.roleId = this.$store.getters.roles.userInfo.roleId // 身份Id 超管才可以编辑
  },
  mounted() {
    this.getListCompany()
  },
  methods: {
    getListCompany() {
      this.searchForm.pageNum = this.pageNum
      this.searchForm.pageSize = this.pageSize
      this.isLoading = true
      searchCompany(this.searchForm).then(response => {
        if (response.code === '000000' && response.data.list) {
          this.isLoading = false
          this.dataList = response.data.list
          this.total = response.data.total
        }
      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getListCompany()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getListCompany()
    },
    deleteCompany(ucId) {
      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.isLoading = true
          deleteCompany(ucId).then(response => {
            if (response.code === '000000') {
              this.$message({
                message: '删除成功',
                type: 'success'
              })
              this.getListCompany()
            } else {
              this.$message.error('删除失败')
            }
          }).catch(() => {
            this.isLoading = false
          })
        })
        .catch(() => {
          this.isLoading = false
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    closeDialog() {
      this.editVisable = false
    },
    addSuccess() {
      this.editVisable = false
      this.getListCompany()
    },
    addClick() {
      this.editData.type = 'add'
      this.editVisable = true
    },
    editClick(row) {
      this.editData.type = 'edit'
      this.editData.ucId = row.ucId
      this.editVisable = true
    },
    // 点击编辑系统
    systemClick(ucId, usIds) {
      this.systemVisible = true
      // 获取该公司已有的系统
      getUcSystem(ucId).then(res => {
        if (res.code === '000000' && res.data) {
          this.editSystemData.usIds = res.data.selectSystemIdList
        }
      })
      this.editSystemData.ucId = ucId
      // this.editSystemData.usIds = []
      // 获取系统列表
      getUserSystem().then(response => {
        if (response.code === '000000' && response.data) {
          this.useridAllsystem = response.data
        }
      })
    },
    commitEditSystem() {
      editUcSystem(this.editSystemData).then(response => {
        if (response.code === '000000') {
          this.$message({
            message: '修改所属系统成功',
            type: 'success'
          })
          this.getListCompany()
        } else {
          this.$message.error('修改失败')
        }
      }).catch(err => {
        console.log(err)
      })
      this.systemVisible = false
    }
  }
}
</script>
